import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import ErrorBox from 'components/ErrorBox'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'
import { SendVZFMailState, useVZFView } from './useVZFView'

const VZFView = (): JSX.Element => {
    const { t } = useTranslation()
    const { errorState, sendVZFMailState, currentView, email, mailValid, onDownloadVZFPDF, onVZFEmailSend, setEmail } =
        useVZFView()
    return (
        <ViewWrapper header={currentView + 'Header'} viewType={currentView} subHeader={''} disabledSubmit={false}>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('vzf.vzfInfo')}</Typography>
                    <br />
                    <Typography variant="h4">{t('vzf.vzfInfo2')}</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container item>
                        {errorState.trim().length > 0 && <ErrorBox errors={[{ category: '', errors: [errorState] }]} />}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        {...TestIDs.GET_VZF('GET_VZF_VIEW_EMAIL')}
                        fullWidth
                        label={t('email')}
                        placeholder="max.mustermann@email.com"
                        variant="outlined"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        display="flex"
                        flex={1}
                        justifyContent={{ xs: 'stretch', sm: 'flex-end', md: 'flex-end', lg: 'flex-end' }}
                    >
                        <Button
                            {...TestIDs.GET_VZF('GET_VZF_VIEW_SEND')}
                            disabled={
                                email.trim().length === 0 || !mailValid || sendVZFMailState != SendVZFMailState.READY
                            }
                            disableFocusRipple={true}
                            className={clsx([
                                'FormButton',
                                'NextButton',
                                'SendVZFMailButton',
                                sendVZFMailState === SendVZFMailState.SENDING && 'Loading',
                                sendVZFMailState === SendVZFMailState.SENT && 'Send',
                            ])}
                            onClick={onVZFEmailSend}
                        >
                            {sendVZFMailState === SendVZFMailState.READY && t('vzf.vzfEmailButton')}
                            {sendVZFMailState === SendVZFMailState.SENDING && (
                                <>
                                    {t('saveOrderProgressSending')}
                                    <div className={'animation-loader'} />
                                </>
                            )}
                            {sendVZFMailState === SendVZFMailState.SENT && t('saveOrderProgressSent')}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="body1">{t('vzf.vzfInfoDownload')}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        display="flex"
                        flex={1}
                        justifyContent={{ xs: 'stretch', sm: 'flex-end', md: 'flex-end', lg: 'flex-end' }}
                    >
                        <Button
                            disableFocusRipple={true}
                            className={clsx(['FormButton', 'NextButton'])}
                            onClick={onDownloadVZFPDF}
                        >
                            {t('vzf.vzfDownloadButton')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default VZFView
