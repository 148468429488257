import { useLazyQuery, useMutation } from '@apollo/client'
import { VZFSaveParams } from 'components/ViewWrapper/useViewWrapperReducer'
import SaveVZF from 'graphql/mutations/SaveVZF'
import SendVZFMailAndSave from 'graphql/mutations/SendVZFMailAndSave'
import GenerateVZF from 'graphql/queries/GenerateVZF'
import { MultipleSelectOptions, Mutation, Query, VzfpdfData } from 'graphql/types'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ContactDataActions from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import { stateToVZF } from 'utils/convertStateToVZF'
import { generateVZFDataForText } from 'utils/generateVZFDataForText'
import getEnvConfig from 'utils/getEnvConfig'
import { mailIsValid } from 'utils/testable/functions'
import useURLParams from 'utils/URLParamsContex'
export enum SendVZFMailState {
    READY,
    SENDING,
    SENT,
}
export type VZFEmailSendParams = {
    vzfID: string
    pdfData: VzfpdfData
    input: {
        additionalInfo: string
        city: string
        houseNumber: string
        startOfMarketing: Date | undefined
        startOfDelivery: Date | undefined
        selectedProductCategories: string
        multipleSelectOptionList: MultipleSelectOptions[]
        street: string
        zipcode: string
        email: string
    }
    B2B: boolean
}
export interface VZFState {
    errorState: string
    sendVZFMailState: SendVZFMailState
    email: string
    mailValid: boolean
    currentView: ViewType
    vzfID: string
    vzfSummaryData: VzfpdfData
    B2B: boolean
    onVZFEmailSend: () => void
    onDownloadVZFPDF: () => void
    setEmail: (payload: string) => void
    introText: string[]
}
export const useVZFView = (): VZFState => {
    const { t } = useTranslation()
    const envConfig = getEnvConfig()
    const dispatch = useDispatch<Dispatch<GeneralStateActions | ContactDataActions>>()
    const { customizeJsData, appState, generalState } = useSelector((appState: AppState) => {
        return {
            appState: appState,
            generalState: appState.generalState,
            customizeJsData: appState.generalState.customizeJsData,
        }
    })
    const introText = customizeJsData?.vzfConfiguration.introText ?? []
    const { B2B } = useURLParams()
    const [sendVZFMailState, setSendVZFMailState] = useState<SendVZFMailState>(SendVZFMailState.READY)
    const [sendVZFMailCounter, setSendVZFMailCounter] = useState(1)
    const vzfSummaryData = generateVZFDataForText(appState, t, B2B, customizeJsData)
    const [errorState, setErrorState] = useState('')
    const setVZFMail = useCallback(
        (payload: string): void => {
            dispatch({ type: GeneralStateAction.SET_VFZMAIL, payload })
        },
        [dispatch],
    )

    const [sendVZFMailAndSave] = useMutation<Mutation>(SendVZFMailAndSave, {
        onCompleted: (data) => {
            if (data.sendVZFMailAndSave && data.sendVZFMailAndSave.status !== 'success') {
                setErrorState('send_email')
            }

            const timeout = sendVZFMailCounter * 5000
            setSendVZFMailState(SendVZFMailState.SENT)
            setTimeout(() => {
                setSendVZFMailState(SendVZFMailState.READY)
                setVZFMail('')
            }, timeout)
            setSendVZFMailCounter(sendVZFMailCounter + 1)
        },
    })
    const [saveVzf] = useMutation<Mutation>(SaveVZF)

    const [generateVZFID] = useLazyQuery<Query>(GenerateVZF, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.generateVZFID) {
                dispatch({ type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL, payload: { vzfID: data.generateVZFID } })
            }
        },
    })

    const onDownloadVZFPDF = (): void => {
        const vzfData = stateToVZF(appState)
        const variables: VZFSaveParams = {
            vzfID: generalState.vzfID,
            input: {
                additionalInfo: vzfData.additionalInfo,
                city: vzfData.city,
                houseNumber: vzfData.houseNumber,
                startOfMarketing: generalState.startOfMarketing,
                startOfDelivery: generalState.startOfDelivery,
                selectedProductCategories: vzfData.selectedProductCategories,
                multipleSelectOptionList: vzfData.multipleSelectOptionList,
                street: vzfData.street,
                zipcode: vzfData.zipcode,
                email: vzfData.email,
            },
        }
        saveVzf({ variables })

        const serverURI =
            process.env.NODE_ENV !== 'production' ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/` : '/'

        fetch(serverURI + 'vzfdf/Vertragszusammenfassung_' + generalState.vzfID + '.pdf', {
            method: 'POST',
            body: JSON.stringify({ vzfSummaryData: vzfSummaryData, B2B }),
        }).then((e) => {
            if (e.status === 200) {
                e.blob().then((blob) => {
                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    a.setAttribute('type', 'hidden')
                    a.target = '_blank'
                    a.download = 'Vertragszusammenfassung_' + generalState.vzfID + '.pdf'
                    document.body.appendChild(a)
                    a.click()
                })
            }
        })
    }

    const onVZFEmailSend = (): void => {
        const vzfData = stateToVZF(appState)
        const variables: VZFEmailSendParams = {
            vzfID: generalState.vzfID,
            pdfData: vzfSummaryData,
            input: {
                additionalInfo: vzfData.additionalInfo,
                city: vzfData.city,
                houseNumber: vzfData.houseNumber,
                startOfMarketing: generalState.startOfMarketing,
                startOfDelivery: generalState.startOfDelivery,
                selectedProductCategories: vzfData.selectedProductCategories,
                multipleSelectOptionList: vzfData.multipleSelectOptionList,
                street: vzfData.street,
                zipcode: vzfData.zipcode,
                email: vzfData.email,
            },
            B2B: B2B,
        }
        setErrorState('')
        setSendVZFMailState(SendVZFMailState.SENDING)
        sendVZFMailAndSave({ variables })
    }

    useEffect(() => {
        if (generalState.vzfID === '') {
            generateVZFID()
        }
    }, [])

    const mailValid = mailIsValid(generalState.vzfMail)

    return {
        errorState,
        sendVZFMailState,
        email: generalState.vzfMail,
        mailValid,
        currentView: generalState.currentView,
        vzfID: generalState.vzfID,
        onVZFEmailSend,
        setEmail: setVZFMail,
        onDownloadVZFPDF,
        vzfSummaryData,
        B2B,
        introText,
    }
}
