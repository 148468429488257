import { Box, Grid, Typography } from '@material-ui/core'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'
import { useVZFLandingpage } from './useVZFLandingpage'

const VZFLandingpage = (): JSX.Element => {
    const { t } = useTranslation()
    const { disabledSubmit, currentView } = useVZFLandingpage()

    return (
        <ViewWrapper
            header={currentView + 'Header'}
            viewType={currentView}
            subHeader={''}
            disabledSubmit={disabledSubmit}
        >
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography
                        {...TestIDs.GET_VZF('VZF_ID')}
                        variant="h4"
                        dangerouslySetInnerHTML={{
                            __html: t('vzf.vzfViewListEntry'),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box width={1}>
                        <Typography variant={'h2'} className={'SectionCategory'}>
                            {t('orderOverviewStrings.costsHeader')}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default VZFLandingpage
