import { BillMode, VoucherCurrency, VzfpdfData, VzfpdfCost, VzfpdfProductData } from 'graphql/types'
import { TFunction } from 'i18next'
import { AppState } from 'store/store'
import { formatCurrency } from 'utils/numberFormat'
import { getMultiplicator } from 'utils/stateToPDFData'
import { basketCalculation, vzfCalculation, formatSelectedProductCategory } from 'utils/testable/basketCalculation'
import { Customize } from './customize'

export const generateVZFDataForText = (
    state: AppState,
    t: TFunction,
    B2B: boolean,
    customizeJsData: Customize | undefined,
): VzfpdfData => {
    const processUniqueFeeList: string[] = []
    const processUniqueVoucherList: string[] = []

    const pickIntroText = () => {
        const translated: string[] = []
        if (customizeJsData) {
            if (customizeJsData.vzfConfiguration.introText.length > 0) {
                if (customizeJsData.vzfConfiguration.introText.length > 1) {
                    customizeJsData.vzfConfiguration.introText.forEach((text) => {
                        translated.push(t(`vzf.${text}`))
                    })
                } else {
                    translated.push(t(`vzf.${customizeJsData.vzfConfiguration.introText[0]}`))
                }
            }
        }
        return translated
    }
    const pickFooterText = () => {
        const translated: { name: string; page: number }[] = []
        if (customizeJsData) {
            if (customizeJsData.vzfConfiguration.footNoteText.length > 0) {
                if (customizeJsData.vzfConfiguration.footNoteText.length > 0) {
                    customizeJsData.vzfConfiguration.footNoteText.forEach((text) => {
                        translated.push({ name: t(`vzf.${text.name}`), page: text.page })
                    })
                }
            }
        }
        return translated
    }
    const vzfData: VzfpdfData = {
        products: [],
        text: {
            intro: pickIntroText(),
            footer: pickFooterText(),
        },
        functionalFeaturesDisability: t('vzf.vzfDoesntApply'),
        costHint1: t('vzf.vzfCostHint1'),
        costHint2: t('vzf.vzfCostHint2'),
        costHint3: t('vzf.vzfCostHint3'),
        vzfID: state.generalState.vzfID,
    }

    state.generalState.selectedProductCategories.forEach((selectedProductCategory) => {
        const selectedProduct = selectedProductCategory.selectedProduct

        // Get selected product category data from available categories
        const productCategory = state.generalState.availableProductCategories.find(
            (availableProductCategory) => availableProductCategory.id === selectedProductCategory.id,
        )
        if (!productCategory || !selectedProduct) return

        const vzfProduct: VzfpdfProductData = {
            optionalCosts: [],
            services: [],
            devices: [],
            costs: {
                monthlyCost: '',
                oneTimeCost: '',
                monthlyItems: [],
                oneTimeItems: [],
                categoryCost: [],
                totalDiscounts: {
                    sum: '',
                    discounts: [],
                },
            },
            internet: undefined,
            contractTerms: [],
            productTitle: '',
        }

        const costs = vzfCalculation(
            selectedProductCategory,
            state.bankDetails,
            state.generalState,
            processUniqueFeeList,
            processUniqueVoucherList,
            B2B,
        )
        const monthlyCost = costs?.monthlyCost ?? 0
        vzfProduct.costs.monthlyCost = formatCurrency(monthlyCost)
        vzfProduct.costs.oneTimeCost = formatCurrency(costs?.oneTimeCost ?? 0)

        vzfProduct.costs.totalDiscounts.sum = formatCurrency(costs?.totalDiscounts.sum ?? 0)

        costs &&
            costs.totalDiscounts.discounts.forEach((discount) => {
                vzfProduct.costs.totalDiscounts.discounts.push({
                    name: discount.name,
                    oldPrice: formatCurrency(discount.oldPrice),
                    newPrice: formatCurrency(discount.newPrice),
                })
            })

        // Get selected product data from available products
        const product = productCategory.products.find((availableProduct) => availableProduct.id === selectedProduct.id)
        if (!product) return
        state.generalState.availableProductCategories.forEach((availableProductCategory) => {
            const prd = availableProductCategory.products.find((p) => p.id === product.id)
            if (prd) {
                vzfProduct.productTitle = prd.title
            }
        })

        if (product.minimumContractPeriod && product.minimumContractPeriod.trim().length > 0) {
            vzfProduct.contractTerms.push(
                t('vzf.vzfContractTermText', {
                    product: parseHTML(product.title),
                    minimumContractPeriod: product.minimumContractPeriod,
                }),
            )
        }

        selectedProduct.productTypes.forEach((selectedProductType) => {
            const informationList: string[] = []
            const productType = product.productTypes.find(
                (availableProductType) => availableProductType.id === selectedProductType.id,
            )
            if (!productType) return

            if (product.footnoteText.trim().length > 0) informationList.push(product.footnoteText)
            if (productType.footnoteText.trim().length > 0) informationList.push(productType.footnoteText)

            // Get all selected option category data from available option categories
            selectedProductType.optionCategories.forEach((selectedOptionCategory) => {
                const optionCategory = productType.category.find(
                    (availableOptionCategory) => availableOptionCategory.id === selectedOptionCategory.id,
                )
                if (!optionCategory) return

                // Get all selected option data from available options
                selectedOptionCategory.selectedOptions.forEach((selectedOption) => {
                    const option = optionCategory.options.find(
                        (availableOption) => availableOption.id === selectedOption,
                    )
                    if (option) {
                        if (option.footnoteText.trim().length > 0) {
                            if (option.isHardware) {
                                vzfProduct.devices.push(option.footnoteText)
                            } else {
                                informationList.push(option.footnoteText)
                            }
                        }
                    }
                    if (option && option.minimumContractPeriod && option.minimumContractPeriod.trim().length > 0) {
                        vzfProduct.contractTerms.push(
                            t('vzf.vzfContractTermText', {
                                product: option.title,
                                minimumContractPeriod: option.minimumContractPeriod,
                            }),
                        )
                    }
                })
            })

            const serviceName = selectedProduct.productTypes.length > 1 ? productType.title : parseHTML(product.title)
            vzfProduct.services.push({
                title: serviceName,
                type: productType.identifier,
                information: informationList,
            })

            if (productType.identifier === 'internet') {
                vzfProduct.internet = {
                    speedTerm: t('vzf.vzfConnectionIssueInfo'),
                    upload: {
                        max: productType.upload,
                        normal: productType.uploadStandard,
                        min: productType.uploadMinimal,
                    },
                    download: {
                        max: productType.download,
                        normal: productType.downloadStandard,
                        min: productType.downloadMinimal,
                    },
                }
            }
        })

        const formattedProductCategory = formatSelectedProductCategory(
            customizeJsData,
            state.bankDetails,
            state.generalState.optionsMultipleSelect,
            state.generalState.configuration,
            state.generalState.availableProductCategories,
            selectedProductCategory,
            processUniqueFeeList,
            processUniqueVoucherList,
            B2B,
            state.generalState.voucher,
            true,
        )

        for (const product of formattedProductCategory.products) {
            const category = getVZFCostCategory(product.category, t)
            let index = vzfProduct.costs.categoryCost.findIndex((e) => e.title === category.title)
            if (index === -1) {
                vzfProduct.costs.categoryCost.push({
                    sort: category.sort,
                    title: category.title,
                    entries: [],
                })
                const lenght = vzfProduct.costs.categoryCost.length
                index = lenght > 0 ? lenght - 1 : 0
            }

            let price = ''

            if (product.value.billMode === BillMode.VOUCHER) {
                let billMode = BillMode.ONE_TIME
                if (product.value.voucherData?.month !== undefined && product.value.voucherData?.month > 1) {
                    billMode = BillMode.RECURRING_MONTHLY
                }

                vzfProduct.costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value:
                        product.value.voucherData?.currency === VoucherCurrency.EURO
                            ? '-' + formatCurrency(product.value.newPrice)
                            : '-' + product.value.newPrice + '%',
                    billMode: billMode,
                })
            } else {
                const multiplicator = getMultiplicator(product.value.multiple)
                let basePrice = 0.0
                if (product.value.oldPrice !== undefined) {
                    basePrice = product.value.oldPrice * multiplicator
                } else {
                    basePrice = product.value.newPrice * multiplicator
                }

                if (product.value.billMode === BillMode.RECURRING_QUARTERLY_PP) {
                    basePrice /= 3
                } else if (product.value.billMode === BillMode.RECURRING_HALF_YEARLY_PP) {
                    basePrice /= 6
                } else if (product.value.billMode === BillMode.RECURRING_YEARLY_PP) {
                    basePrice /= 12
                }

                price = formatCurrency(basePrice)

                vzfProduct.costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value: product.value.oldPrice === undefined ? price : formatCurrency(product.value.newPrice),
                    billMode: product.value.billMode,
                })
            }
        }

        vzfProduct.costs.categoryCost.sort((a, b) => {
            if (a.sort < b.sort) {
                return -1
            }
            if (a.sort > b.sort) {
                return 1
            }

            return 0
        })

        let productTitles = ''
        let feeTotal = 0

        if (
            customizeJsData &&
            customizeJsData.vzfConfiguration.optionalFees &&
            customizeJsData.vzfConfiguration.optionalFees.length > 0
        ) {
            customizeJsData.vzfConfiguration.optionalFees.forEach((fee, i) => {
                feeTotal = feeTotal + fee.value
                productTitles = productTitles + `${i !== 0 ? ',' : ''} ${t('vzf.vzfOptionalCosts.' + fee.name)}`
            })
        }

        if (customizeJsData && customizeJsData.vzfConfiguration.optionalFees) {
            vzfProduct.optionalCosts.push({
                title: `${t('vzf.vzfTotalFromStartOfContract')} ${productTitles}`,
                value: formatCurrency(monthlyCost + feeTotal),
            })
        }

        costs?.monthlyIncrements.forEach((increment) => {
            vzfProduct.costs.monthlyItems.push({
                title: increment.title,
                value: formatCurrency(increment.value.newPrice),
            })
            if (
                customizeJsData &&
                customizeJsData.vzfConfiguration.optionalFees &&
                customizeJsData.vzfConfiguration.optionalFees.length > 0
            ) {
                vzfProduct.optionalCosts.push({
                    title: `${t('vzf.vzfTotalFromMonth', { month: increment.title })} ${productTitles}`,
                    value: formatCurrency(increment.value.newPrice + feeTotal),
                })
            }
        })

        vzfData.products.push(vzfProduct)
    })

    return vzfData
}

export const generateSummaryCosts = (state: AppState, t: TFunction, B2B: boolean): VzfpdfCost => {
    const costs: VzfpdfCost = {
        monthlyCost: '',
        oneTimeCost: '',
        monthlyItems: [],
        oneTimeItems: [],
        categoryCost: [],
        totalDiscounts: {
            sum: '',
            discounts: [],
        },
    }

    const calculationBasket = basketCalculation(state.availabilityCheck, state.bankDetails, state.generalState, B2B)
    const monthlyCost = calculationBasket.costs?.monthlyCost ?? 0
    costs.monthlyCost = formatCurrency(monthlyCost)
    costs.oneTimeCost = formatCurrency(calculationBasket.costs?.oneTimeCost ?? 0)

    costs.totalDiscounts.sum = formatCurrency(calculationBasket.costs?.totalDiscounts.sum ?? 0)

    calculationBasket.costs &&
        calculationBasket.costs.totalDiscounts.discounts.forEach((discount) => {
            costs.totalDiscounts.discounts.push({
                name: discount.name,
                oldPrice: formatCurrency(discount.oldPrice),
                newPrice: formatCurrency(discount.newPrice),
            })
        })

    for (const productCategory of calculationBasket.products.productCategories) {
        for (const product of productCategory.products) {
            const category = getVZFCostCategory(product.category, t)
            let index = costs.categoryCost.findIndex((e) => e.title === category.title)
            if (index === -1) {
                costs.categoryCost.push({
                    sort: category.sort,
                    title: category.title,
                    entries: [],
                })
                const lenght = costs.categoryCost.length
                index = lenght > 0 ? lenght - 1 : 0
            }

            let price = ''

            if (product.value.billMode === BillMode.VOUCHER) {
                let billMode = BillMode.ONE_TIME
                if (product.value.voucherData?.month !== undefined && product.value.voucherData?.month > 1) {
                    billMode = BillMode.RECURRING_MONTHLY
                }

                costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value:
                        product.value.voucherData?.currency === VoucherCurrency.EURO
                            ? '-' + formatCurrency(product.value.newPrice)
                            : '-' + product.value.newPrice + '%',
                    billMode: billMode,
                })
            } else {
                const multiplicator = getMultiplicator(product.value.multiple)
                let basePrice = 0.0
                if (product.value.oldPrice !== undefined) {
                    basePrice = product.value.oldPrice * multiplicator
                } else {
                    basePrice = product.value.newPrice * multiplicator
                }

                if (product.value.billMode === BillMode.RECURRING_QUARTERLY_PP) {
                    basePrice /= 3
                } else if (product.value.billMode === BillMode.RECURRING_HALF_YEARLY_PP) {
                    basePrice /= 6
                } else if (product.value.billMode === BillMode.RECURRING_YEARLY_PP) {
                    basePrice /= 12
                }

                price = formatCurrency(basePrice)

                costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value: product.value.oldPrice === undefined ? price : formatCurrency(product.value.newPrice),
                    billMode: product.value.billMode,
                })
            }
        }
    }

    costs.categoryCost.sort((a, b) => {
        if (a.sort < b.sort) {
            return -1
        }
        if (a.sort > b.sort) {
            return 1
        }

        return 0
    })

    calculationBasket.costs?.monthlyIncrements.forEach((increment) => {
        costs.monthlyItems.push({
            title: increment.title,
            value: formatCurrency(increment.value.newPrice),
        })
    })

    return costs
}

const parseHTML = (html: string): string => {
    const div = document.createElement('div')
    div.innerHTML = html.replace(/&#173;/g, '')
    return div.innerText
}

interface VZFPriceCategory {
    title: string
    sort: number
}

const getVZFCostCategory = (identifier: string, t: TFunction): VZFPriceCategory => {
    switch (identifier) {
        case 'combi':
        case 'combi-custom':
        case 'internet':
        case 'internetService':
            return { title: t('vzf.remunerationIpServiceHeadline'), sort: 0 }
            break
        case 'internetHardware':
            return { title: t('vzf.remunerationIpHardwareHeadline'), sort: 1 }
            break
        case 'television':
        case 'televisionService':
            return { title: t('vzf.remunerationTVServiceHeadline'), sort: 2 }
            break
        case 'televisionHardware':
            return { title: t('vzf.remunerationTVHardwareHeadline'), sort: 3 }
            break
        case 'telephone':
        case 'telephoneService':
            return { title: t('vzf.remunerationTelephoneServiceHeadline'), sort: 4 }
            break
        case 'telephoneHardware':
            return { title: t('vzf.remunerationTelephoneHardwareHeadline'), sort: 5 }
            break
        default:
            return { title: t('vzf.priceComponentsHeadline'), sort: 6 }
    }
}
