import { Dispatch, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GeneralState, OnEditClickTypes, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import { Customize } from 'utils/customize'
import { generateExcelData } from 'utils/generateExcelData'
import { generateVZFDataForText } from 'utils/generateVZFDataForText'
import getEnvConfig from 'utils/getEnvConfig'
import { pathToViewType } from 'utils/testable/pathToViewType'
import useURLParams from 'utils/URLParamsContex'
import * as XLSX from 'xlsx'
import { AvailabilityCheckState } from '../../store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState } from '../../store/BankDetails/BankDetails.reducer'
import { ContactDataState } from '../../store/ContactData/ContactData.reducer'
import GeneralStateActions, { GeneralStateAction } from '../../store/GeneralState/GeneralState.actions'
import { PortabilityState } from '../../store/PortabilityState/PortabilityState.reducer'

interface OrderOverviewReducerReturn {
    address: AvailabilityCheckState
    bankDetails: BankDetailsState
    contactData: ContactDataState
    currentView: ViewType
    disabledSubmit: boolean
    generalState: GeneralState
    portabilityState: PortabilityState
    landLineContractOptionsExit: boolean
    onDownloadVZFPDF: () => void
    onDownloadExcel: () => void
    toggleConfigValue: (identifier: string, value: string) => void
    productsText: string
    productsInformationSheetsText: string
    customizeJsData: Customize | undefined
    vzfID: string
    onEditClick: () => void
    setEditType: (editType: OnEditClickTypes | undefined) => void
}

export const useOrderOverviewReducer: () => OrderOverviewReducerReturn = () => {
    const envConfig = getEnvConfig()
    const { t } = useTranslation()
    const { B2B } = useURLParams()
    const history = useHistory()
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const {
        appState,
        address,
        bankDetails,
        contactData,
        currentView,
        generalState,
        portabilityState,
        customizeJsData,
        pagesList,
    } = useSelector((appState: AppState) => {
        return {
            appState: appState,
            address: appState.availabilityCheck,
            bankDetails: appState.bankDetails,
            contactData: appState.contactData,
            currentView: appState.generalState.currentView,
            generalState: appState.generalState,
            portabilityState: appState.portabilityState,
            customizeJsData: appState.generalState.customizeJsData,
            pagesList: appState.generalState.pagesList,
        }
    })

    const landLineContractOptionsExit =
        generalState.pagesList.findIndex((l) => pathToViewType(l.path) === ViewType.LANDLINE_CONTRACT_OPTIONS) !== -1

    const toggleConfigValue = useCallback(
        (identifier: string, value: string) => {
            dispatch({
                type: GeneralStateAction.SET_CONFIG_VALUE,
                payload: {
                    identifier,
                    value,
                },
            })
        },
        [dispatch],
    )

    const disabledSubmit = useMemo(() => {
        let disabled = false
        if (generalState.customizeJsData) {
            generalState.customizeJsData.orderOverview.legalTerms.checkboxes.forEach((checkbox) => {
                if (disabled === false) {
                    const valueToCheck = generalState.configuration.get(checkbox.value)
                    if (!(checkbox.isDisplayedInB2B === false && B2B === true)) {
                        if (valueToCheck === 'false' || valueToCheck === undefined) {
                            disabled = true
                        }
                    }
                }
            })
            generalState.customizeJsData.orderOverview.vzf.checkboxes.forEach((value) => {
                if (disabled === false) {
                    const valueToCheck = generalState.configuration.get(value)
                    if (valueToCheck === 'false' || valueToCheck === undefined) {
                        disabled = true
                    }
                }
            })
        } else {
            disabled = true
        }

        return disabled
    }, [generalState])

    const productsText = useMemo(() => {
        const productsText: string[] = []
        generalState.selectedProductCategories.forEach((p) => {
            for (let i = 0; i < generalState.availableProductCategories.length; i++) {
                const selectedProductID = p.selectedProduct ? p.selectedProduct.id : ''
                if (generalState.availableProductCategories[i].id === p.id) {
                    generalState.availableProductCategories[i].products.forEach((availableProduct) => {
                        if (availableProduct.id === selectedProductID) {
                            productsText.push(availableProduct.title)
                        }
                    })
                }
            }
        })
        return productsText.join(', ')
    }, [generalState.selectedProductCategories, generalState.availableProductCategories])

    const productsInformationSheetsText = useMemo(() => {
        const productsInformationSheetsText: string[] = []
        generalState.selectedProductCategories.forEach((p) => {
            for (let i = 0; i < generalState.availableProductCategories.length; i++) {
                const selectedProductID = p.selectedProduct ? p.selectedProduct.id : ''
                if (generalState.availableProductCategories[i].id === p.id) {
                    generalState.availableProductCategories[i].products.forEach((availableProduct) => {
                        if (availableProduct.id === selectedProductID && availableProduct.dataSheet) {
                            productsInformationSheetsText.push(
                                '<a target="_blank" href="' +
                                    availableProduct.dataSheet +
                                    '" >' +
                                    availableProduct.title +
                                    '</a>',
                            )
                        }
                    })
                }
            }
        })
        return productsInformationSheetsText.join(', ')
    }, [generalState.selectedProductCategories, generalState.availableProductCategories])

    const vzfSummaryData = generateVZFDataForText(appState, t, B2B, customizeJsData)

    const onDownloadVZFPDF = (): void => {
        const serverURI =
            process.env.NODE_ENV !== 'production' ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/` : '/'

        fetch(serverURI + 'vzfdf/Vertragszusammenfassung_' + generalState.vzfID + '.pdf', {
            method: 'POST',
            body: JSON.stringify({ vzfSummaryData: vzfSummaryData, B2B }),
        }).then((e) => {
            if (e.status === 200) {
                e.blob().then((blob) => {
                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    a.setAttribute('type', 'hidden')
                    a.target = '_blank'
                    a.download = 'Vertragszusammenfassung_' + generalState.vzfID + '.pdf'
                    document.body.appendChild(a)
                    a.click()
                })
            }
        })
    }
    const editPage = pagesList.find((page) => page.path === '/Bearbeiten')

    const onEditClick = () => {
        history.push(editPage ? editPage.path : pagesList[10].path)
    }

    const setEditType = useCallback(
        (payload: OnEditClickTypes | undefined): void => {
            dispatch({ type: GeneralStateAction.SET_EDIT_TYPE, payload })
        },
        [dispatch],
    )

    const onDownloadExcel = (): void => {
        const workbook = XLSX.utils.book_new()
        const excelData = generateExcelData(appState, t, B2B, customizeJsData)

        const worksheetProducts = XLSX.utils.aoa_to_sheet(excelData.products)
        XLSX.utils.book_append_sheet(workbook, worksheetProducts, 'Produkte')

        const worksheetAddresses = XLSX.utils.aoa_to_sheet(excelData.addresses)
        XLSX.utils.book_append_sheet(workbook, worksheetAddresses, 'Kontakte')

        const worksheetTelephone = XLSX.utils.aoa_to_sheet(excelData.telephone)
        XLSX.utils.book_append_sheet(workbook, worksheetTelephone, 'Telefonoptionen')

        const worksheetOther = XLSX.utils.aoa_to_sheet(excelData.other)
        XLSX.utils.book_append_sheet(workbook, worksheetOther, 'Bank Installation')

        XLSX.writeFileXLSX(workbook, 'order_process_export.xlsx')
    }

    return {
        address,
        bankDetails,
        contactData,
        currentView,
        disabledSubmit,
        generalState,
        portabilityState,
        landLineContractOptionsExit,
        toggleConfigValue,
        productsText,
        customizeJsData,
        onDownloadVZFPDF,
        onDownloadExcel,
        productsInformationSheetsText,
        vzfID: generalState.vzfID,
        onEditClick,
        setEditType,
    }
}
